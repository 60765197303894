.notes_list {
  list-style: none;
  margin-block-start: 0em;
  padding-inline-start: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.notes_list li {
  padding-top: 10px;
}

.notebook_list_bg {
  position: relative;
}

.thumb_select {
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 1000;
}

.note_list_card:hover {
  background-color: #f7f7f7;
}

.viewnotethumb_box:hover {
  background-color: #f7f7f7;
}

.viewnotethumb_loading {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: lightgray;
  border-radius: 10px;
  padding-left: 10px;
  transform: scale(1);
}

.viewnotethumb_box {
  display: flex;
  flex-flow: column;
  min-height: calc(var(--viewnotethumb-box-min-height) * 1px);
  max-height: 200px;
  overflow: hidden;
  width: 100%;
}

.thumb_image_loading {
  min-height: calc(var(--viewnotethumb-box-min-height) * 1px);
  max-height: 300px;
  overflow-y: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 4px;
}

@keyframes fadeIt {
  0% {
    background-color: rgb(241, 241, 241);
  }
  50% {
    background-color: rgb(255, 255, 255);
  }
  100% {
    background-color: rgb(241, 241, 241);
  }
}

.backgroundAnimated {
  background-image: none !important;
  animation: fadeIt 1s ease-in-out infinite;
}

.thumb_image {
  min-height: calc(var(--viewnotethumb-box-min-height) * 1px);
  min-height: 45px;
  max-height: 300px;
  overflow-y: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
}

.thumb_outer_link {
  cursor: pointer;
  width: 100%;
}

.thumb_outer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  min-height: 81px;
}

.thumb_outer a {
  width: 100%;
}

.thumb_outer .edit_link {
  width: 100%;
}
