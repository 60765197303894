.breadcrumb_link {
  display: flex;
  align-items: center;
}

.breadcrumb_link_icon {
  font-size: var(--size-7) !important;
  display: flex;
  align-items: center;
  min-width: 27.5px;
}

.breadcrumb_link .note {
  transform: rotate(90deg);
}

.breadcrumb_link_btn:hover {
  text-decoration: underline;
  cursor: pointer;
}

.breadcrumb_container {
  position: relative;
  width: 100%;
  height: calc(var(--breadcrumb-height) * 1px);
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
  box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%),
    0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%);
  justify-content: space-between;
  z-index: 6;
}

.breadcrumbs_inner {
  width: 100%;
  display: flex;
  color: rgba(0, 0, 0, 0.6);
  align-items: center;
}

.breadcrumb_edit_btn {
  position: absolute;
  height: 100%;
  top: 0;
  display: flex;
  align-content: center;
  align-items: center;
  right: 15px;
}

.edit_btn {
  color: rgba(0, 0, 0, 0.54);
}

.breadcrumb_seperator {
  margin-left: 2px !important;
  margin-right: 2px !important;
  font-size: 1rem;
}
